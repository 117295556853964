import { Chip } from '@pennfoster/pfc-design-system';
import { ProgramStatus, ProgramStatusLabels } from 'src/types/program-status';

interface Props {
  status: ProgramStatus;
}

export function MyProgramStatusChip({ status }: Props) {
  const programStatusColorMap = {
    [ProgramStatus.Active]: 'secondary',
    [ProgramStatus.Graduated]: 'success',
    [ProgramStatus.Inactive]: 'error',
    [ProgramStatus.RequirementsNotMet]: 'warning',
    [ProgramStatus.Enrolled]: 'disabled',
    [ProgramStatus.Expired]: 'error',
  };
  return (
    <Chip
      aria-label={`Status: ${ProgramStatusLabels[status]}`}
      backgroundColor={`${programStatusColorMap[status]}.container`}
      borderColor={`${programStatusColorMap[status]}.light`}
      size={'small'}
      variant={'outlined'}
      label={ProgramStatusLabels[status]}
    ></Chip>
  );
}
