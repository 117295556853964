import { useQuery } from 'react-query';
import QueryKeys from '../types/query-keys';
import { createRoom } from 'src/api';

export const useCreateRoom = (toggle: boolean) => {
    return useQuery(
        [QueryKeys.CREATE_ROOM],
        createRoom,
        {
        enabled: !!toggle,
        }
    );
};
