import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Divider } from '@mui/material';
import { Button } from '@pennfoster/pfc-design-system';
import { useState, useEffect } from 'react';

interface EnrollmentExtensionDialogProps {
  open: boolean;
  onClose: () => void;
  expirationDate?: string | null;
}

export default function IneligibleToPurchaseExtensionDialog({
  open,
  onClose,
  expirationDate,
}: EnrollmentExtensionDialogProps) {
  const [monthsRemaining, setMonthsRemaining] = useState<number | null>(null);

  useEffect(() => {
    if (expirationDate) {
      const today = new Date();
      const expiration = new Date(expirationDate);

      const yearDiff = expiration.getFullYear() - today.getFullYear();
      const monthDiff = expiration.getMonth() - today.getMonth();

      const totalMonths = yearDiff * 12 + monthDiff;
      setMonthsRemaining(totalMonths);
    }
  }, [expirationDate]);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '0' } }}>
      <DialogTitle sx={{ pt: 0, pl: 1, pr: 3, mb: 2 }}>
        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
          Ineligible For Extension
        </Typography>
      </DialogTitle>
      <Divider sx={{ mb: 3, mt: -1 }} />
      <DialogContent sx={{ padding: 0, mb: 2 }}>
        <Typography variant="body1" sx={{ textAlign: 'left' }}>
        {monthsRemaining !== null && monthsRemaining > 0
            ? `You have enrolled in your next semester. You will be eligible for an extension in [${monthsRemaining}] months.`
            : ''}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ marginTop: 2, padding: 0, justifyContent: 'flex-end' }}>
        <Button
          pfVariant="filled"
          onClick={onClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '80px',
            position: 'relative',
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
