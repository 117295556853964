import { DialogTitle, DialogContent, Box, useTheme, Typography, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog } from '@pennfoster/pfc-design-system';
import FeEvents from 'src/events/fe-events';
import { useCreateRoom } from 'src/hooks/cobrowse';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import QueryKeys from 'src/types/query-keys';
import { cms } from 'src/helpers/language';

interface CoBrowseDialogProps {
  toggle: boolean;
  handleClose: () => void;
}

export default function CoBrowseDialog({ toggle, handleClose }: CoBrowseDialogProps) {
  const queryClient = useQueryClient();
  const [shouldCreate, setShouldCreate] = useState(false);
  const theme = useTheme();
  const { data, isError, isSuccess, isIdle } = useCreateRoom(shouldCreate);
  const content = cms.content.general.modals.cobrowse;

  const closeDialog = () => {
    setShouldCreate(false);
    queryClient.removeQueries({ queryKey: [QueryKeys.CREATE_ROOM] });
    handleClose();
  };
  return (
    <Dialog onClose={closeDialog} open={toggle}>
      <DialogTitle
        sx={{
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'center',
        }}
      >
        <Typography variant="subtitle1">{content.title}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minWidth: '320px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        {!isIdle ||
          (!isError && <Typography variant="body1">{content.message}</Typography>)}
        {isSuccess && data && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="subtitle1">Room Number</Typography>
            <Box
              sx={{
                background: theme.palette.neutral[80],
                padding: theme.spacing(1),
                borderRadius: theme.spacing(1),
                mt: theme.spacing(2),
              }}
            >
              <Typography variant="headingH4">{data.data.room}</Typography>
            </Box>
          </Box>
        )}
        {isError && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="subtitle1">Error</Typography>
            <Box
              sx={{
                margin: `${theme.spacing(2)} 0`,
              }}
            >
              <Typography variant="subtitle1">There was an error creating your room</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ marginTop: 2, mx: 'auto', padding: 0 }}>
        {!isIdle ||
          (!isError && (
            <Button
              label="Start Session"
              pfVariant="filled"
              onClick={() => {
                setShouldCreate(true);
                FeEvents.trackNamedEvent({
                  eventName: 'CreateCoBrowsingSession',
                });
              }}
            />
          ))}
        {isSuccess && data && (
          <Button
            label="Enter Room"
            pfVariant="filled"
            onClick={() => {
              window.open(data.data.privateinvitation);
            }}
          />
        )}
        {isError && <Button label="Close" pfVariant="filled" onClick={closeDialog} />}
      </DialogActions>
    </Dialog>
  );
}
