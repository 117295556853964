import ROUTES from 'src/routes/routemap';
export const DASHBOARD_CRUMB = 'Home';
export const PAYMENTS_OVERVIEW_CRUMB = 'Payments Overview';
export const MANAGE_MY_BILL_CRUMB = 'Manage My Bill';
export const PAY_BALANCE_CRUMB = 'Pay Balance';
export const MAKE_A_PAYMENT_CRUMB = 'Make a Payment';
export const MANAGE_PAYMENT_CRUMB = 'Manage Payment';
export const PURCHASE_AN_EXTENSION_CRUMB = 'Purchase an Extension';
export const PROGRAMS_CRUMB = 'Courses & Grades';
export const ELECTIVES_CRUMB = 'Choose Your Electives';
export const PROFILE_CRUMB = 'Profile';
export const PATHWAY_CRUMB = 'Pathway';
export const ADMIN_CRUMB = 'Admin';

export const CRUMBS = {
  DASHBOARD: {
    label: DASHBOARD_CRUMB,
    route: ROUTES.DASHBOARD,
  },
  PAYMENTS_OVERVIEW: {
    label: PAYMENTS_OVERVIEW_CRUMB,
    route: ROUTES.PAYMENTS_OVERVIEW,
  },
  MANAGE_MY_BILL: {
    label: MANAGE_MY_BILL_CRUMB,
    route: ROUTES.MANAGE_MY_BILL,
  },
  PAY_BALANCE: {
    label: PAY_BALANCE_CRUMB,
    route: ROUTES.PAY_BALANCE,
  },
  MAKE_A_PAYMENT: {
    label: MAKE_A_PAYMENT_CRUMB,
    route: ROUTES.MAKE_A_PAYMENT,
  },
  MANAGE_PAYMENT: {
    label: MANAGE_PAYMENT_CRUMB,
    route: ROUTES.MANAGE_PAYMENT,
  },
  PURCHASE_AN_EXTENSION: {
    label: PURCHASE_AN_EXTENSION_CRUMB,
    route: ROUTES.PURCHASE_AN_EXTENSION,
  },
  COURSES: {
    label: PROGRAMS_CRUMB,
    route: ROUTES.COURSES,
  },
  ELECTIVES: {
    label: ELECTIVES_CRUMB,
    route: ROUTES.ELECTIVES,
  },
  PROFILE: {
    label: PROFILE_CRUMB,
    route: ROUTES.PROFILE,
  },
  PATHWAY: {
    label: PATHWAY_CRUMB,
    route: ROUTES.PATHWAY,
  },
  ADMIN: {
    label: ADMIN_CRUMB,
    route: ROUTES.ADMIN,
  },
};
export default {
  CRUMBS,
  DASHBOARD_CRUMB,
  PAYMENTS_OVERVIEW_CRUMB,
  MANAGE_MY_BILL_CRUMB,
  PAY_BALANCE_CRUMB,
  MAKE_A_PAYMENT_CRUMB,
  MANAGE_PAYMENT_CRUMB,
  PROGRAMS_CRUMB,
  ELECTIVES_CRUMB,
  PROFILE_CRUMB,
  PATHWAY_CRUMB,
  ADMIN_CRUMB,
};
