import { Button, Card, Typography } from '@pennfoster/pfc-design-system';
import { CardContent, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { Body1, HeadingH6, LabelLarge, Subtitle1 } from 'src/styles';
import EnrollmentExtensionDialog from '../purchase-extension-confirmation-dialog';
import { useState } from 'react';
import { Enrollment } from 'src/types/enrollment';
import { cms } from 'src/helpers/language';
import { ScheduledPayment } from 'src/types/payments';
import routemap from 'src/routes/routemap';
import { useNavigate } from 'react-router-dom';
import IneligibleToPurchaseExtensionDialog from '../ineligible-to-purchase-extension-dialog';


interface PurchaseExtensionCardProps {
    scheduledPayments: ScheduledPayment[];
    enrollment: Enrollment;
}

export default function PurchaseExtensionCard({ scheduledPayments, enrollment }: PurchaseExtensionCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openInelgibleForExtensionDialog, setIneligibleExtensionOpenExtensionDialog] = useState(false);
  const efTransaction = scheduledPayments.find((payment) => payment.billingTransactionCode === 'EF');
  const hasEFTransactionCode = Boolean(efTransaction);

  const [openExtensionDialog, setOpenExtensionDialog] = useState(false);

  const buttonText = hasEFTransactionCode ? cms.content.general.button_labels.purchase_an_extension : cms.content.general.button_labels.request_an_extension;

  const handlePurchaseExtension = () => {
    if (hasEFTransactionCode) {
        if (enrollment.enrollmentExtension.eligibleForExtension) {
            efTransaction &&
          navigate(routemap.PURCHASE_AN_EXTENSION, {
            state: {
              sisPaymentScheduledId: efTransaction.sisPaymentScheduledId,
              billingTransactionCode: efTransaction.billingTransactionCode,
              sisTermId: efTransaction.sisTermId,
              programName: enrollment.program?.programName,
              programDisplayName: enrollment.program?.programDisplayName,
              status: efTransaction.status,
              type: efTransaction.type,
              amountDue: efTransaction.amountDue,
              dueDate: efTransaction.dueDate,
              sisPaymentPlanId: efTransaction.sisPaymentPlanId,
              chargeId: efTransaction.chargeId,
            },
          });
        }
        else{
            setIneligibleExtensionOpenExtensionDialog(true);
        }
        console.log('Purchasing an extension...');
      } else {
        setOpenExtensionDialog(true);
      }
  };

  return (
    <>
        <Card>
      <CardHeader
        title={<Subtitle1>Required Task</Subtitle1>}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <Divider aria-hidden="true" />
        <Typography />
        <Grid container>
          <Grid item xs={12}>
            <HeadingH6
              sx={{ pt: 3 }}
              color={theme.palette.secondary.main}
            >
              Reactivate Your Program Access
            </HeadingH6>
            <Body1 sx={{ pt: 3 }}>
                It looks like your program access has expired. But no worries - you can easily extend it and pick up right where you left off.
            </Body1>
          </Grid>
        </Grid>
      </CardContent>
      <Grid
        sx={{ pb: 3, pr: 2 }}
        item
        xs={12}
        textAlign={'right'}
      >
        <Button pfVariant="filled" onClick={handlePurchaseExtension}>
            <LabelLarge fontFamily={'NunitoSans24'}>{buttonText}</LabelLarge>
        </Button>
      </Grid>
    </Card>
        {!hasEFTransactionCode && (
        <EnrollmentExtensionDialog 
          open={openExtensionDialog} 
          onClose={() => setOpenExtensionDialog(false)} 
          sisEnrollmentId={enrollment.sisEnrollmentId} 
        />
      )}
      <IneligibleToPurchaseExtensionDialog open={openInelgibleForExtensionDialog} onClose={() => setIneligibleExtensionOpenExtensionDialog(false)} expirationDate={enrollment.expirationDate}/>
    </>
  );
}
