import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEolUrl } from 'src/api/enrollments';
import { Subtitle1 } from 'src/styles';
import { Task } from 'src/types/task';
import { CardContent, CardHeader, Divider, List } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';
import { MyTasksCardListItem } from './my-tasks-card-list-item';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import { cms } from 'src/helpers/language';
import { useElectivesRoutes } from 'src/hooks/electives';
import { Enrollment } from 'src/types/enrollment';
import { ScheduledPayment } from 'src/types/payments';
import EnrollmentExtensionDialog from 'src/components/purchase-extension-confirmation-dialog';
import IneligibleToPurchaseExtensionDialog from 'src/components/ineligible-to-purchase-extension-dialog';
import routemap from 'src/routes/routemap';
import EnrollmentContext from 'src/context/enrollment';

interface Props {
  enrollment: Enrollment;
  pathwayStatus: PathwayStatusValues;
  selectedPathway: boolean | null;
  setIsEligibleToReEnroll: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  showLSAPlaceHolders: boolean | null;
  scheduledPayments: ScheduledPayment[];
}

export function MyTasksCard({
  pathwayStatus,
  selectedPathway,
  enrollment,
  setIsEligibleToReEnroll,
  showLSAPlaceHolders,
  scheduledPayments,
}: Props) {
  const { refetch: refetchEnrollment } = useContext(EnrollmentContext);
  const [tasks, setTasks] = useState<Task[]>([]);
  const navigate = useNavigate();
  const content = cms.content.pages.dashboard.cards.my_tasks;
  const { electivePathwayRoute } = useElectivesRoutes();
  const navigateUrl = electivePathwayRoute;
  const [openExtensionDialog, setOpenExtensionDialog] = useState(false);
  const [openInelgibleForExtensionDialog, setIneligibleExtensionOpenExtensionDialog] = useState(false);
  const ENROLL_NEXT_SEMESTER_LABEL = 'Enroll In Next Semester';
  const EF = 'EF';

  const handleEnrollNextSemester = () => {
    const enrollmentId = enrollment?.enrollmentId;
    setIsEligibleToReEnroll(true);
    getEolUrl(enrollmentId)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const hasEFTransactionCode = scheduledPayments.some((payment) => payment.billingTransactionCode === EF);

  useEffect(() => {
    refetchEnrollment();
    const updatedTasks: Task[] = [];
    if (pathwayStatus !== PathwayStatusValues.None && !showLSAPlaceHolders)
      updatedTasks.push({
        label: `${content.action_word} ${
          pathwayStatus === PathwayStatusValues.Pathway ? content.pathways_label : content.electives_label
        }`,
        action: () => {
          navigate(navigateUrl);
        },
      });

    if(hasEFTransactionCode){
      const efPayment = scheduledPayments.find((payment) => payment.billingTransactionCode === EF);
      updatedTasks.push({
        label: cms.content.general.button_labels.purchase_an_extension,
        action: () => {
          if (enrollment.enrollmentExtension.eligibleForExtension) {
            efPayment &&
            navigate(routemap.PURCHASE_AN_EXTENSION, {
              state: {
                sisPaymentScheduledId: efPayment.sisPaymentScheduledId,
                billingTransactionCode: efPayment.billingTransactionCode,
                sisTermId: efPayment.sisTermId,
                programName: enrollment.program?.programName,
                programDisplayName: enrollment.program?.programDisplayName,
                status: efPayment.status,
                type: efPayment.type,
                amountDue: efPayment.amountDue,
                dueDate: efPayment.dueDate,
                sisPaymentPlanId: efPayment.sisPaymentPlanId,
                chargeId: efPayment.chargeId
              },
            });
          } else {
            {setIneligibleExtensionOpenExtensionDialog(true)}
          }
        },
      });
    }  

    if (enrollment.enrollmentExtension.eligibleForExtension && !hasEFTransactionCode) {
      updatedTasks.push({
        label:cms.content.general.button_labels.request_an_extension,
        action: () => {setOpenExtensionDialog(true)},
      });
    }

    if (enrollment?.eligibleToReEnroll) {
      updatedTasks.push({
        label: ENROLL_NEXT_SEMESTER_LABEL,
        action: handleEnrollNextSemester,
      });
    }

    setTasks(updatedTasks);
  }, [pathwayStatus, selectedPathway, enrollment, scheduledPayments]);

  return (
    <>
      <Card>
        <CardHeader sx={{ pl: 3, pr: 3, pt: 3 }} title={<Subtitle1 variant="h2">{content.title}</Subtitle1>} />
        <Divider aria-hidden="true" sx={{ ml: 3, mr: 3 }} />
        <CardContent sx={{ pl: 3, pr: 3 }}>
          <List>
            {tasks.map((task, i) => {
              // TODO: Add task.id to key when available.
              return <MyTasksCardListItem key={`task-${i}`} task={task} />;
            })}
          </List>
        </CardContent>
      </Card>
      <EnrollmentExtensionDialog open={openExtensionDialog} onClose={() => setOpenExtensionDialog(false)} sisEnrollmentId={enrollment.sisEnrollmentId} />
      <IneligibleToPurchaseExtensionDialog open={openInelgibleForExtensionDialog} onClose={() => setIneligibleExtensionOpenExtensionDialog(false)} expirationDate={enrollment.expirationDate}/>
    </>
  );
}
