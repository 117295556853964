enum QueryKeys {
  GET_USER = 'get-user',
  GET_PROFILE = 'get-profile',
  GET_ENROLLMENTS = 'get-enrollments',
  GET_ENROLLMENT = 'get-enrollment',
  GET_DEGREE_AUDIT = 'get-degree-audit',
  GET_INBOX_MESSAGES = 'get-inbox-messages',
  GET_ELECTIVE_POOLS = 'get-elective-pools',
  GET_PAYMENT_METHODS = 'get-payment-methods',
  GET_PAYMENT_METHODS_DEFAULTBACKUP = 'get-payment-methods-default-backup',
  GET_DISPLAY_MESSAGE = 'get-display-message',
  GET_LEDGER_TRANSACTIONS = 'get-ledger-transactions',
  GET_THEMES = 'get-themes',
  GET_STATES = 'get-states',
  GET_COURSE_URL = 'get-course-url',
  GET_ENROLLMENT_COURSES = 'get-enrollment-courses',
  GET_PAYMENT_PLANS = 'get-payment-plans',
  GET_PAYMENT_PLANS_AND_FEES = 'get-payment-plans-fees',
  GET_SCHEDULED_PAYMENTS = 'get-scheduled-payments',
  GET_SAS_TOKEN = 'get-sastoken',
  GET_LOGO = 'get-logo',
  GET_PAYMENT_METHOD_LIST = 'get-payment-method-list',
  GET_ENROLLMENT_SCHEDULED_PAYMENTS = 'get-enrollment-scheduled-payments',
  GET_PATHWAYS = 'get-pathway',
  GET_OUTSTANDING_PROGRAMS = 'get-outstanding-programs',
  GET_NEXT_PAYMENT_DATA = 'get-next-payment-data',
  GET_UPCOMING_COURSES = 'get-upcoming-courses',
  GET_RESOURCE_LINKS = 'get-resource-links',
  GET_USER_ENROLLMENT_DOCUMENTS = 'get-user-enrollment-documents',
  GET_PAYMENT_TAXES = 'get-payment-taxes',
  CREATE_ROOM = 'create-room',
}

export default QueryKeys;
