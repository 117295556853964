/* eslint-disable react/react-in-jsx-scope */
import { AUTH_LOGOUT_CALLBACK } from 'src/routes/routemap';
import LoadingComponent from 'src/components/loading-component';
import { AUTH_HOST } from 'src/auth/auth-host';
import { LOGINSESSION } from 'src/state/session';

const config = process.env;

export default function AuthLogout() {
  localStorage.removeItem(LOGINSESSION);
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');
  window.location.replace(
    `https://${AUTH_HOST}/${config.REACT_APP_AUTH_TENANT}.onmicrosoft.com/${config.REACT_APP_AUTH_POLICY}/oauth2/v2.0/logout?redirect_uri=${window.location.origin}${AUTH_LOGOUT_CALLBACK}`
  );
  return <LoadingComponent label={'Loading'} />;
}
