import { Lesson } from './lesson';
import { ProgressStatus } from './progress-status';

export type CourseId = string;
export type SISCourseId = number;
export type SISStudentCourseId = number;

export enum CourseLocation {
  Engen = 'engen',
}

export interface Course {
  readonly lmsCourseId: number;
  readonly catalogCode: string;
  readonly courseCode: string;
  readonly courseId: CourseId;
  readonly sisCourseId: SISCourseId;
  readonly sisStudentCourseId: SISStudentCourseId;
  readonly name: string;
  readonly description: string;
  readonly letterGrade: string;
  readonly numericGrade: number;
  readonly status: ProgressStatus;
  readonly creditAmount: number;
  readonly creditAttempted: number;
  readonly creditEarned: number;
  readonly lessons: Lesson[];
  readonly completionDate?: string;
  readonly courseLocation?: CourseLocation;
  readonly termId: number;
}

export interface MostRecentGradedCourse {
  completionDate?: string;
  grade?: string;
}
