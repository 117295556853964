import React, { useContext, useEffect, useState, useReducer } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { initialState, reducer } from '../make-a-payment-form/state';
import { addPaymentMethod } from 'src/api';
import amexLightIcon from 'src/assets/svg/amex24x16.svg';
import discoVerIcon from 'src/assets/svg/discover24x16.svg';
import masterCardIcon from 'src/assets/svg/mastercard24x16.svg';
import { ReactComponent as PadLockIcon } from 'src/assets/svg/padlock.svg';
import visaIcon from 'src/assets/svg/visa24x16.svg';
import {
  CC_CVV_REGEX,
  CC_EXP_DATE_REGEX,
  CC_NUMBER_GROUPED_REGEX,
  CC_NUMBER_REGEX,
  ZIP_CODE_REGEX,
} from 'src/constants/regex';
import PaymentMethodContext from 'src/context/payment-method';
import { normalizeCardNumber, normalizeExpiry } from 'src/helpers/normalize';
import { LabelSmall } from 'src/styles';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment, TextField, FormControlLabel, Checkbox, Tooltip, Typography } from '@mui/material';

import ProcessingState from '../../processing-state';
import ErrorMessage from '../error-message';
import { getErrorMessage } from 'src/helpers/handling-errors';
import { PaymentMethod } from 'src/types/payments';
import { Button } from '@pennfoster/pfc-design-system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { cms } from 'src/helpers/language';

interface IFormInput {
  nameOnCard: string | null;
  cardNumber: string | null;
  cardNumberHidden?: string | null;
  expiration: string | null;
  cvv: string | null;
  zipcode: string | null;
  isDefault: boolean | null;
  isBackup: boolean | null;
}

interface AddCardFormProps {
  handleClose: () => void;
  emitNewCard?: (paymentMethod: PaymentMethod) => void;
}

const styleSecurePaymnt = {
  ml: '24px',
  size: '12px',
  lineHeight: '16px',
  fontWeight: '500',
  color: 'rgba(129, 141, 164, 1)',
};

const styleCardBox = {
  height: '16px',
  width: '24px',
  mr: '4px',
};

const styleTextField = {
  height: '56px',
  margin: 0,
  padding: 0,
  width: '100%',
};

const errorMessageContent = cms.content.errors.generic_has_errors_above.details;

export default function AddCardForm({ handleClose, emitNewCard }: AddCardFormProps) {
  const { errorsFromAPI, setErrorsFromAPI, setRefreshPaymentMethods, paymentMethodSelected, setPaymentMethodSelected } =
    useContext(PaymentMethodContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [creditCardNumber, setCreditCardNumber] = useState<string>('');
  const [creditCardMasked, setCreditCardMasked] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [firstCardNumber, setFirstCardNumber] = useState<string>('');
  const [expiration, setExpiration] = useState<string>('');
  const [cardImage, setCardImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mutation = useMutation(async (data: IFormInput) => {
    setIsLoading(true);
    setValidationError('');
    setErrorsFromAPI('');
    setPaymentMethodSelected({
      nameOnCard: data.nameOnCard || '',
      cardNumber: data.cardNumberHidden || '',
      expiration: data.expiration || '',
      zipCode: data.zipcode || '',
      isDefault: data.isDefault || false,
      isBackup: false,
    });

    const creditCardInfo = {
      number: data.cardNumber,
      expiration: data.expiration,
      cvv: data.cvv,
      nameOnCard: data.nameOnCard,
      isDefault: data.isDefault,
      isBackup: data.isBackup,
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: data.zipcode || '',
      },
    };

    return new Promise((resolve, reject) => {
      addPaymentMethod(creditCardInfo)
        .then((result) => {
          const resultData: PaymentMethod = result.data;
          const paymentMethodCreated = {
            id: resultData?.id,
            brand: resultData?.brand,
            expiration: resultData?.expiration,
            isDefault: resultData?.isDefault,
            isBackup: resultData?.isBackup,
            lastFour: resultData?.lastFour,
            nameOnCard: resultData?.nameOnCard,
            type: resultData?.type,
          };
          paymentMethodCreated.isDefault ?? dispatch({ type: 'SET_DEFAULT_CARD', payload: resultData });
          setIsLoading(false);
          handleClose();
          emitNewCard && emitNewCard(paymentMethodCreated);
          resolve(result);
          setRefreshPaymentMethods(true);
          setPaymentMethodSelected({
            nameOnCard: '',
            cardNumber: '',
            expiration: '',
            zipCode: '',
            isDefault: false,
            isBackup: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage = getErrorMessage(error);
            setErrorsFromAPI(errorMessage);
          } else {
            setErrorsFromAPI(error.message);
          }
          setIsLoading(false);
          reject(error);
        });
    });
  });

  const schema = yup.object({
    nameOnCard: yup
      .string()
      .matches(/^([a-zA-Z]+\s)+[a-zA-Z]+ ?$/, 'Enter your Full Name')
      .required('Cardholder Name is a Required Field'),

    cardNumberHidden: yup
      .string()
      .transform(() => creditCardNumber.replace(/\D/g, ''))
      .max(19)
      .matches(CC_NUMBER_REGEX, 'Invalid Card Number')
      .required('Card Number is a Required Field'),

    expiration: yup
      .string()
      .length(5, 'Valid Format is MM/YY')
      .matches(CC_EXP_DATE_REGEX, 'Invalid Expiration Date')
      .test('is-expired', 'The Date is Expired', (value) => {
        const currentDate = new Date();
        const [month, year] = value!.split('/');

        const expirationDate = new Date(parseInt('20' + year), parseInt(month) - 1);

        return expirationDate >= currentDate;
      })
      .required('Expiration Date is a Required Field'),
    cvv: yup.string().max(4).matches(CC_CVV_REGEX, 'Invalid CVV').required(),
    zipcode: yup.string().max(10).matches(ZIP_CODE_REGEX, 'Invalid Zip Code').required('Zip Code is a Required Field'),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const tempData = {
      cardNumber: data.cardNumberHidden || '',
      cvv: data.cvv,
      expiration: data.expiration,
      nameOnCard: data.nameOnCard,
      zipcode: data.zipcode,
      isDefault: data.isDefault,
      isBackup: false,
    };
    mutation.mutate(tempData);
  };

  useEffect(() => {
    let imgIcon;
    switch (firstCardNumber) {
      case '3':
        imgIcon = amexLightIcon;
        break;
      case '4':
        imgIcon = visaIcon;
        break;
      case '5':
        imgIcon = masterCardIcon;
        break;
      case '6':
        imgIcon = discoVerIcon;
        break;
      default:
        imgIcon = '';
        break;
    }
    setCardImage(imgIcon);
  }, [firstCardNumber]);

  const formattedCardNumber = (value: string) => {
    const cardNumber = value.replace(/\D/g, '');
    const formattedCardNumber = cardNumber.replace(CC_NUMBER_GROUPED_REGEX, '•••• •••• •••• $4');
    return formattedCardNumber;
  };

  useEffect(() => {
    const cardInput = document.getElementById('cardNumber') as HTMLInputElement;
    const cardHiddenInput = document.getElementById('cardNumberHidden') as HTMLInputElement;
    cardHiddenInput.value = creditCardNumber.replace(/\D/g, '');

    const value = cardInput.value;
    const masked = formattedCardNumber(value);

    cardInput.addEventListener('blur', function () {
      cardInput.value = masked;
    });
    cardInput.addEventListener('focus', function () {
      cardInput.value = value;
    });
  }, [creditCardMasked, creditCardNumber]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      nameOnCard: paymentMethodSelected && paymentMethodSelected.nameOnCard,
      cardNumber: paymentMethodSelected && paymentMethodSelected.cardNumber,
      cardNumberHidden: paymentMethodSelected && paymentMethodSelected.cardNumber,
      expiration: paymentMethodSelected && paymentMethodSelected.expiration,
      zipcode: paymentMethodSelected && paymentMethodSelected.zipCode,
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setValidationError('Please correct the errors');
      setErrorsFromAPI('');
    } else {
      setValidationError('');
    }
  }, [errors]);

  function DefaultOption() {
    return (
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormControlLabel
          id="isDefault"
          name="isDefault"
          sx={{
            marginLeft: '0',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'row',
          }}
          control={<Checkbox {...register('isDefault')} />}
          label={null}
        />
        <Typography>Set card as default</Typography>
      </Grid>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: '516px' }}>
      {!isLoading ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <hr
            style={{
              border: '1px solid rgba(200, 202, 204, 0.6)',
              maxWidth: '440px',
              margin: '0 0 16px 0',
              width: '100%',
            }}
          />

          <Grid container spacing={3}>
            <Box
              sx={{
                mt: !validationError && !errorsFromAPI ? '24px' : '48px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <LabelSmall sx={styleSecurePaymnt}>Secure Payment</LabelSmall>
                <Box sx={{ ml: '11px', mt: '-0.2em' }}>
                  <PadLockIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '8px',
                  ml: '24px',
                }}
              >
                <Box sx={styleCardBox}>
                  <img src={amexLightIcon} />
                </Box>
                <Box sx={styleCardBox}>
                  <img src={visaIcon} />
                </Box>
                <Box sx={styleCardBox}>
                  <img src={masterCardIcon} />
                </Box>
                <Box sx={styleCardBox}>
                  <img src={discoVerIcon} />
                </Box>
              </Box>
            </Box>
            <Grid item xs={12}>
              <TextField
                sx={styleTextField}
                {...register('nameOnCard')}
                error={!!errors.nameOnCard}
                helperText={errors.nameOnCard ? errors.nameOnCard.message : ''}
                id="nameOnCard"
                name="nameOnCard"
                label="Cardholder Name"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={styleTextField}
                {...register('cardNumber')}
                error={!!errors.cardNumberHidden}
                helperText={errors.cardNumberHidden ? errors.cardNumberHidden?.message : ''}
                id="cardNumber"
                name="cardNumber"
                label="Card Number"
                type="text"
                onChange={(event) => {
                  event.preventDefault();
                  setFirstCardNumber(event.target.value?.substring(0, 1));
                  const { value } = event.target;
                  event.target.value = normalizeCardNumber(value);
                  setCreditCardNumber(value);
                  setCreditCardMasked(formattedCardNumber(value));
                  delete errors.cardNumberHidden;
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {cardImage === '' ? <CreditCardIcon /> : <img src={cardImage} />}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 19,
                  onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.replace(/\s/g, '').length > 16) {
                      e.target.value = e.target.value.slice(0, 19);
                    }
                  },
                }}
              />

              <TextField
                sx={{ display: 'none' }}
                {...register('cardNumberHidden')}
                error={!!errors.cardNumberHidden}
                inputProps={{ maxLength: 19 }}
                id="cardNumberHidden"
                name="cardNumberHidden"
                type="text"
                value={paymentMethodSelected?.cardNumber?.trim() ?? creditCardNumber?.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{
                  backgroundColor: 'rgba(244, 244, 245, 1)',
                  height: '56px',
                  margin: 0,
                  padding: 0,
                }}
                {...register('expiration')}
                error={!!errors.expiration}
                helperText={errors.expiration ? errors.expiration.message : ''}
                inputProps={{ maxLength: 5 }}
                id="expiration"
                name="expiration"
                label="Expiration Date"
                type="text"
                value={expiration}
                InputProps={{
                  placeholder: '00/00',
                }}
                onChange={(event) => {
                  event.preventDefault();
                  const { value } = event.target;
                  setExpiration(normalizeExpiry(value));
                  delete errors.expiration;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{
                  backgroundColor: 'rgba(244, 244, 245, 1)',
                  height: '56px',
                  margin: 0,
                  padding: 0,
                }}
                {...register('cvv')}
                error={!!errors.cvv}
                helperText={errors.cvv ? errors.cvv.message : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ cursor: 'help' }} position="end">
                      <Tooltip title="For Mastercard, Visa, and Discover cards, the CVV is 3 digits on the back of the card. For American Express cards, the CVV is 4 digits on the front of the card.">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 4,
                }}
                id="cvv"
                name="cvv"
                label="CVV"
                type="password"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={styleTextField}
                {...register('zipcode')}
                error={!!errors.zipcode}
                helperText={errors.zipcode ? errors.zipcode.message : ''}
                inputProps={{ maxLength: 10 }}
                id="zipcode"
                name="zipcode"
                label="Zip Code"
                type="text"
              />
            </Grid>
            <DefaultOption />
            {errors && !errorsFromAPI && (
              <Grid item xs={12}>
                <ErrorMessage errors={errors} defaultMessage={errorMessageContent} />
              </Grid>
            )}
            {errorsFromAPI && (
              <Grid item xs={12}>
                <ErrorMessage errors={errorsFromAPI} defaultMessage={errorMessageContent} />
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button label="Cancel" onClick={handleClose} size="small" />
              <Button pfVariant="filled" label="Add" type="submit" size="small" disabled={isLoading ? true : false} />
            </Grid>
          </Grid>
        </form>
      ) : (
        <ProcessingState>
          Verifying card details with Chase Payment Solutions<sup>SM</sup>
        </ProcessingState>
      )}
    </Box>
  );
}
