import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import vwoSdk from 'vwo-node-sdk';
import { VWOInstance } from '../../types/vwo-instance';
import { VWOSettingsFile } from 'src/types/vwo-settings-file';

// Define a custom interface for the vwoSdk module.
interface VwoSdkInterface {
  getSettingsFile(accountId: number, sdkKey: string): Promise<VWOSettingsFile>;
  launch(options: { sdkKey: string; settingsFile: VWOSettingsFile }): Promise<VWOInstance>;
}

// Cast vwoSdk to our custom interface.
const vwo = vwoSdk as unknown as VwoSdkInterface;

interface VWOContextType {
  vwoInstance: VWOInstance | null;
}

const VWOContext = createContext<VWOContextType | undefined>(undefined);

export const VWOProvider = ({ children }: { children: ReactNode }) => {
  const [vwoInstance, setVWOInstance] = useState<VWOInstance | null>(null);
  const accountId = process.env.REACT_APP_VWO_ACCOUNT_ID;
  const sdkKey = process.env.REACT_APP_VWO_SDK_KEY;

  useEffect(() => {
    if (!accountId || !sdkKey) {
      console.error("Missing VWO account ID or SDK key.");
      return;
    }

    vwo.getSettingsFile(Number(accountId), sdkKey)
      .then((rawSettings: unknown) => {
        const settingsFile = rawSettings as VWOSettingsFile;
        return vwo.launch({
          sdkKey,
          settingsFile,
        }) as unknown as Promise<VWOInstance>;
      })
      .then((instance: VWOInstance) => {
        setVWOInstance(instance);
      })
      .catch((error: Error) => {
        console.error("Error initializing VWO:", error);
      });
  }, [accountId, sdkKey]);

  return (
    <VWOContext.Provider value={{ vwoInstance }}>
      {children}
    </VWOContext.Provider>
  );
};

export const useVWO = (): VWOContextType => {
  const context = useContext(VWOContext);
  if (!context) {
    throw new Error("useVWO must be used within a VWOProvider");
  }
  return context;
};