import React from 'react';
import { DOMNode, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { isTag } from 'domhandler';
import { getSasToken, getHtmlBlob } from 'src/api';
import { NotificationsAction } from 'src/context/notifications/actions';
import { purifyAndParse } from 'src/helpers/html-parse';
import { InboxMessage } from 'src/types/message';
import {
  OrderedList,
  UnorderedList,
  desktopMessageH1,
  desktopMessageH2,
  desktopMessageH3,
  desktopMessageH4,
  desktopMessageH5,
  desktopMessageH6,
} from 'src/styles';
import { Button } from '@pennfoster/pfc-design-system';
import { Link, SxProps, Theme } from '@mui/material';
import PurchaseExtensionButton from 'src/components/extensions-action-button';

interface ButtonProps {
  pfVariant?: 'text' | 'filled' | 'outlined' | 'elevated' | 'tonal';
  sx?: SxProps<Theme>;
  href?: string;
}

const customizedComponents: Record<string, React.ComponentType<any> | string> = {
  h1: desktopMessageH1,
  h2: desktopMessageH2,
  h3: desktopMessageH3,
  h4: desktopMessageH4,
  h5: desktopMessageH5,
  h6: desktopMessageH6,
  button: Button,
  ul: UnorderedList,
  ol: OrderedList,
  a: Link,
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (isTag(domNode) && Object.prototype.hasOwnProperty.call(customizedComponents, domNode.name)) {
      const CustomizedComponent = customizedComponents[domNode.name];

      if(domNode.attribs.type === 'action-button'){
        const buttonProps: ButtonProps = {
          pfVariant: 'outlined',
          sx: {
            display: 'block',
            marginTop: 3,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2,
          } as SxProps<Theme>,
        };
        return <PurchaseExtensionButton/>
      }

      if (domNode.name === 'button') {
        const buttonProps: ButtonProps = {
          pfVariant: 'outlined',
          sx: {
            display: 'block',
            marginTop: 3,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2,
          } as SxProps<Theme>,
        };

        return React.createElement(Button, buttonProps, domToReact(domNode.children as DOMNode[], options));
      }

      if (domNode.name === 'a' && domNode.attribs.type === 'button') {
        const buttonProps: ButtonProps = {
          pfVariant: 'filled',
          sx: {
            marginTop: 3,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2,
          } as SxProps<Theme>,
          href: domNode.attribs.href,
        };

        return React.createElement(Button, buttonProps, domToReact(domNode.children as DOMNode[], options));
      }

      return React.createElement(CustomizedComponent, {}, domToReact(domNode.children as DOMNode[], options));
    }
    return null;
  },
};

export async function setSelectedMessage(
  message: InboxMessage | undefined,
  dispatch: React.Dispatch<NotificationsAction>
) {
  if (message) {
    const messageHtml = await getMessageHtml(message);
    message.body = messageHtml as string;
    dispatch({
      type: 'SET_SELECTED_MESSAGE',
      payload: message,
    });
  } else {
    dispatch({
      type: 'SET_SELECTED_MESSAGE',
      payload: undefined,
    });
  }
}

export async function setUnreadOnly(includeRead: boolean, dispatch: React.Dispatch<NotificationsAction>) {
  dispatch({
    type: 'SET_UNREAD_ONLY',
    payload: includeRead,
  });
}

export async function getMessageHtml({ id, blobUrl }: InboxMessage) {
  const storedMessages = sessionStorage.getItem('messages');
  if (storedMessages === null) {
    sessionStorage.setItem('messages', '{}');
  }
  const storedMessageHtml = (storedMessages && JSON.parse(storedMessages)[id]) || undefined;
  if (storedMessageHtml) {
    return purifyAndParse(storedMessageHtml, options);
  }

  const resp = await getSasToken(blobUrl);
  const token = resp.data;
  const response = await getHtmlBlob(token);

  const cacheMessages = sessionStorage.getItem('messages');
  const parsedMessages = cacheMessages && JSON.parse(cacheMessages);
  const updatedMessages = {
    ...parsedMessages,
    [`${id}`]: response.data,
  };
  sessionStorage.setItem('messages', JSON.stringify(updatedMessages));
  return purifyAndParse(response.data, options);
}
