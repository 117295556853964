import React, { useContext, useEffect, useState } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { Subtitle1 } from 'src/styles';
import { ProgressStatus } from 'src/types/progress-status';

import { Alert, Box, CardContent, CircularProgress, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Button, Card } from '@pennfoster/pfc-design-system';

import ProgressChip from '../../components/progress-chip';
import useCourseUrls from '../../hooks/use-course-url';
import { Course } from '../../types/course';
import { Enrollment } from '../../types/enrollment';
import { LessonStatusIcon } from '../lesson-status-icon/';
import { cms } from 'src/helpers/language';
const {no_courses} = cms.content.alerts
const {continue_learning_disabled} = cms.content.pages.dashboard.cards.current_course

const CurrentCourseCard = ({
                             course,
                             enrollment,
                             setIsLoadingUrlCourses,
                           }: {
  course: Course;
  enrollment: Enrollment;
  setIsLoadingUrlCourses: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}) => {
  const hasCourse = course && course.sisStudentCourseId;

  // TODO: We don't have design mockups for the event that no courses are returned.
  if (!hasCourse) {
    return (
      <Card sx={{ pl: 4, pr: 4, pb: 4, pt: 4 }}>
        <CardContent sx={{ padding: 3 }}>
          <Alert severity={'error'}>{no_courses}</Alert>
        </CardContent>
      </Card>
    );
  }

  const theme = useTheme();
  const { enrollmentId } = useContext(EnrollmentContext).enrollment!;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useCourseUrls.enrollmentId = enrollmentId;
  const { handleCourseRedirect, queryStatus } = useCourseUrls;
  let { currentCourseId } = useCourseUrls;

  useEffect(() => {
    if (queryStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [queryStatus]);

  return (
    <Card sx={{ pl: 4, pr: 4, pb: 4, pt: 4 }}>
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{
            borderBottom: '1px solid lightgray',
            pb: '12px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Subtitle1 variant="h2">Current Course</Subtitle1>
            </Grid>

            <Grid item xs={6} textAlign="right">
              <ProgressChip status={course.status} isStatusForLesson={false} />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              padding: '32px 0px',
            }}
          >
            <Typography variant={'headingH6'} color={theme.palette.secondary.main}>
              {course.name}
            </Typography>
            <Box>
              {course.lessons &&
                course.lessons.map((lesson, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      pb: theme.spacing(0.5),
                    }}
                  >
                    <LessonStatusIcon status={lesson.status} />
                    <Typography sx={{ ml: theme.spacing(1) }}>{lesson.name}</Typography>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
        <Grid item textAlign={'end'}>
          <Tooltip
            title={
              !enrollment.learningEnabled &&
              !enrollment.accountStatus.learningEnabled &&
              continue_learning_disabled
            }
            placement="top"
          >
            <div style={{ display: 'inline-block' }}>
              <Button
                label={isLoading && course.sisStudentCourseId === currentCourseId ? '' : 'Continue Learning'}
                pfVariant="filled"
                onClick={() => {
                  currentCourseId = course.sisStudentCourseId;
                  handleCourseRedirect(course, setIsLoadingUrlCourses);
                }}
                size="large"
                disabled={course.status === ProgressStatus.Locked || !enrollment.learningEnabled}
                data-course-id={course.sisStudentCourseId}
              >
                {isLoading && course.sisStudentCourseId === currentCourseId ? (
                  <CircularProgress sx={{ color: 'white', ml: 1 }} size={'16px'} />
                ) : null}
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CurrentCourseCard;
