import React, { useContext, useEffect, useState } from 'react';
import { CardContent, CardActions, Divider, Box, CardHeader } from '@mui/material';
import { Button, Card, Typography } from '@pennfoster/pfc-design-system';
import { useNavigate } from 'react-router-dom';
import FeEvents from 'src/events/fe-events';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import { MAKE_A_PAYMENT } from 'src/routes/routemap';
import { Subtitle1, Body1 } from 'src/styles';
import { NextPaymentData, PaymentPlans, ScheduledPayment } from 'src/types/payments';
import EnrollmentContext from 'src/context/enrollment';
import AccountStatusesChip from 'src/components/account-status-chip';
import {
  getUxStatus,
  getPaymentDescription,
  paidDescription,
  paidStatus,
  getPaymentEnrollment,
} from 'src/helpers/paymentHelper';
import { getProgramNameByParams } from 'src/helpers/get-program-name';

interface ScheduledPaymentProps {
  paymentScheduled: ScheduledPayment[];
  paymentPlans: PaymentPlans[];
}

export default function NextPaymentCard({ paymentScheduled, paymentPlans }: ScheduledPaymentProps) {
  const { userEnrollment, enrollment } = useContext(EnrollmentContext);  
  paymentScheduled = paymentScheduled.filter(payment => payment.billingTransactionCode !== 'EF');
  const paymentEnrollment = getPaymentEnrollment(paymentScheduled, userEnrollment?.enrollments, enrollment);
  const accountStatus = paymentEnrollment?.accountStatus;
  const [descriptionCard, setDescriptionCard] = useState<string>('');
  const status: string = (accountStatus && getUxStatus(accountStatus, paymentEnrollment.learningEnabled)) ?? '';

  const navigate = useNavigate();
  const handleClick = () => {
    FeEvents.trackNamedEvent({
      eventName: 'MakeAPaymentClick',
    });
    navigate(MAKE_A_PAYMENT);
  };

  useEffect(() => {
    let nextPaymentData: NextPaymentData;
    if (status === paidStatus) setDescriptionCard(paidDescription);

    const sisPaymentPlanId = paymentScheduled[0]?.sisPaymentPlanId;
    const currentPaymentPlan = paymentPlans.find((item) => item.paymentPlanSisId === sisPaymentPlanId);

    if (paymentScheduled[0]?.type !== 'Fee') {
      nextPaymentData = {
        amountDue: paymentScheduled[0]?.amountDue,
        dueDate: paymentScheduled[0]?.dueDate,
        paymentPlanType: currentPaymentPlan?.type ?? '',
        programName: getProgramNameByParams(currentPaymentPlan?.programDisplayName, currentPaymentPlan?.programName),
        paymentScheduleType: 'tuition',
      };
    } else {
      nextPaymentData = {
        amountDue: paymentScheduled[0].amountDue,
        dueDate: paymentScheduled[0].dueDate,
        paymentPlanType: '',
        programName: getProgramNameByParams(paymentScheduled[0].programDisplayName, paymentScheduled[0].programName),
        paymentScheduleType: 'fee',
      };
    }

    if (nextPaymentData.amountDue !== undefined && nextPaymentData.dueDate !== undefined) {
      setDescriptionCard(getPaymentDescription(status, nextPaymentData, paymentEnrollment));
    }
  }, [paymentScheduled, paymentPlans]);

  const hasPayment = paymentScheduled && paymentScheduled.length > 0;
  const textWithSpace = '(plus tax)';
  return (
    <>
      <Card
        pfVariant="default"
        borderColor="common.white"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CardHeader
          sx={{ flex: 0, px: 3, pt: 3, pb: 2 }}
          title={<Subtitle1>Next Payment</Subtitle1>}
          action={<AccountStatusesChip params={status} />}
        />
        <Divider aria-hidden="true" sx={{ margin: '0 24px' }} />
        <CardContent sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography variant="labelDisplay" sx={{ pb: 3 }}>
                {status !== paidStatus && hasPayment
                  ? currencyFormatWithDecimals(paymentScheduled[0].amountDue)
                  : '$ 0.00'}
              </Typography>
              {hasPayment && (
                <Typography variant="labelLarge" sx={{ margin: '4px 0px 0px 4px' }}>
                  {textWithSpace}
                </Typography>
              )}
            </Box>
            <Body1>{descriptionCard}</Body1>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 24px 24px 0',
          }}
        >
          <Button
            size="large"
            label="Make a Payment"
            disabled={!!(!hasPayment || accountStatus?.paymentsBlocked)}
            pfVariant="filled"
            onClick={handleClick}
          />
        </CardActions>
      </Card>
    </>
  );
}
