import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Divider } from '@mui/material';
import { Button } from '@pennfoster/pfc-design-system';
import { useNavigate } from 'react-router-dom';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import { requestExtension } from 'src/api/enrollments';
import { useState } from 'react';
import { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { useFetchScheduledPayments } from 'src/hooks/fetch-payments';


interface EnrollmentExtensionDialogProps {
  open: boolean;
  onClose: () => void;
  sisEnrollmentId: number;
}

export default function EnrollmentExtensionDialog({ open, onClose, sisEnrollmentId }: EnrollmentExtensionDialogProps) {
  const navigate = useNavigate();
  const { refetch } = useContext(EnrollmentContext);
  const scheduledPaymentsQuery = useFetchScheduledPayments();
  const [loading, setLoading] = useState(false);

  const handleConfirmExtension = async () => {
    setLoading(true);
    try {
      await requestExtension(sisEnrollmentId);
      await refetch();
      await scheduledPaymentsQuery.refetch();
      onClose();
      navigate('/payments-overview/purchase-an-extension');
    } catch (error) {
      console.error('Error requesting extension:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      PaperProps={{ sx: { borderRadius: '0' } }}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle sx={{ pt: 0, pl: 1, pr: 3, mb: 2 }}>
        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
          Confirm Extension Request
        </Typography>
      </DialogTitle>
      <Divider sx={{ mb: 3, mt: -1 }} />
      <DialogContent sx={{ padding: 0, mb: 2 }}>
        <Typography variant="labelDisplay" sx={{ pb: 3 }}>
          {currencyFormatWithDecimals(125.0)}
        </Typography>
        <br />
        <br />
        <Typography variant="body1" sx={{ textAlign: 'left' }}>
          You are requesting an extension to your study program. This will add a service fee to your account.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ marginTop: 2, padding: 0, justifyContent: 'flex-end' }}>
        <Button pfVariant="text" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button
          pfVariant="filled"
          onClick={handleConfirmExtension}
          disabled={loading}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '140px',
            position: 'relative'
          }}
        >
          {loading ? (
            'Confirming'
          ) : (
            'Confirm'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
