import { refreshToken as refreshAuthToken } from './refresh-token';

class AuthTokens {
  private accessToken = '';
  private refreshToken = localStorage.getItem('refreshToken') ?? sessionStorage.getItem('refreshToken') ?? '';

  public setAccessToken = (token: string) => (this.accessToken = token);
  public setRefreshToken = (token: string) => (this.refreshToken = token);

  public getAccessToken = () => this.accessToken;
  public getRefreshToken = () => this.refreshToken;

  public refresh = () => refreshAuthToken;
}

export default new AuthTokens();
