import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@pennfoster/pfc-design-system';
import { MAKE_A_PAYMENT, PAYMENTS_OVERVIEW, PURCHASE_AN_EXTENSION } from 'src/routes/routemap';
import { useNavigate } from 'react-router-dom';
import { cms } from 'src/helpers/language';

interface ActionButtonsProps {
  blockPayments: boolean;
  hasOnlyEFTransactionCode?: boolean;
}

export default function ActionButtons({ blockPayments, hasOnlyEFTransactionCode = false }: ActionButtonsProps) {
  const content = cms.content.general.button_labels;
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!hasOnlyEFTransactionCode) {
      navigate(MAKE_A_PAYMENT);
    } else {
      navigate(PURCHASE_AN_EXTENSION);
    }
  };

  return (
    <>
      <Button
        role="button"
        sx={{ m: 1 }}
        size="large"
        color="primary"
        pfVariant="text"
        component={Link}
        label={content.view_payments}
        to={PAYMENTS_OVERVIEW}
      />

      <Button
        label={content.make_a_payment}
        sx={{ textTransform: 'initial' }}
        size="large"
        color="primary"
        variant="outlined"
        disabled={blockPayments}
        onClick={handleClick}
      />
    </>
  );
}
