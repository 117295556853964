import adaEmbed from '@ada-support/embed2';
import { Enrollment } from 'src/types/enrollment';
import { User } from 'src/types/user';
import useCreditPercentage from './credit-percentage';
import { DegreeAuditTerm } from 'src/types/degree-audit-term';

  function constructMetaData(user: User, enrollment: Enrollment, selectedTerm: DegreeAuditTerm) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      satisfiestudentNumber: user.studentNumber,
      campusCode: enrollment.campusCode,
      campusName: enrollment.campusName,
      term: enrollment.currentTerm,
      degreeCode: enrollment.degree?.code,
      degreeName: enrollment.degree?.name,
      programDisplayName: enrollment.program?.programDisplayName || enrollment.program?.programName || '',
      enrollmentDate: enrollment.enrollmentDate,
      expirationDate: enrollment.expirationDate,
      academicStatus: enrollment.academicStatus.name,
      accountStatus: enrollment.accountStatus.name,
      currentBalance: enrollment.totalBalance,
      programCompletionPercentage: useCreditPercentage(enrollment, selectedTerm)
    }
  }

export function useChatbot() {

  const start = async (user: User, enrollment: Enrollment, selectedTerm: DegreeAuditTerm) => {
    const metadata = constructMetaData(user, enrollment, selectedTerm);

    await adaEmbed.start({
      handle: 'pennfostergroup',
      lazy: true,
      metaFields: metadata,
    });
  };

  const stop = () => {
    adaEmbed.stop();
  };

  const toggle = () => {
    adaEmbed.toggle();
  };

  return {
    stop,
    start,
    toggle,
  };
}
