import { Button, Card, Typography } from '@pennfoster/pfc-design-system';
import { CardContent, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { Body1, HeadingH6, LabelLarge } from 'src/styles';
import EnrollmentExtensionDialog from '../purchase-extension-confirmation-dialog';
import IneligibleToPurchaseExtensionDialog from '../ineligible-to-purchase-extension-dialog';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchScheduledPayments } from 'src/hooks/fetch-payments';
import { cms } from 'src/helpers/language';
import EnrollmentContext from 'src/context/enrollment';
import routemap from 'src/routes/routemap';

export default function PurchaseExtensionButton() {
  const { enrollment } = useContext(EnrollmentContext);

  const navigate = useNavigate();
  const [openExtensionDialog, setOpenExtensionDialog] = useState(false);
  const [openInelgibleForExtensionDialog, setIneligibleExtensionOpenExtensionDialog] = useState(false);
  const { data: scheduledPayments = [], isLoading, error } = useFetchScheduledPayments();
  const EF = 'EF'

  const efPayment = scheduledPayments.find((payment) => payment.billingTransactionCode === EF);
  const hasEFTransactionCode = Boolean(efPayment);

  const buttonText = hasEFTransactionCode
    ? cms.content.general.button_labels.purchase_an_extension
    : cms.content.general.button_labels.request_an_extension;

  const handlePurchaseExtension = () => {
    if (enrollment?.enrollmentExtension.eligibleForExtension && !hasEFTransactionCode) {
      setOpenExtensionDialog(true);
    } else if (!enrollment?.enrollmentExtension.eligibleForExtension && hasEFTransactionCode) {
      setIneligibleExtensionOpenExtensionDialog(true);
    } else {
        efPayment &&
          navigate(routemap.PURCHASE_AN_EXTENSION, {
            state: {
              sisPaymentScheduledId: efPayment?.sisPaymentScheduledId,
              billingTransactionCode: efPayment?.billingTransactionCode,
              sisTermId: efPayment?.sisTermId,
              programName: enrollment?.program?.programName,
              programDisplayName: enrollment?.program?.programDisplayName,
              status: efPayment?.status,
              type: efPayment?.type,
              amountDue: efPayment?.amountDue,
              dueDate: efPayment?.dueDate,
              sisPaymentPlanId: efPayment?.sisPaymentPlanId,
              chargeId: efPayment?.chargeId,
            },
          });
    }
  };

  return (
    <>
      <Button pfVariant="filled" onClick={handlePurchaseExtension}>
        <LabelLarge fontFamily={'NunitoSans24'}>{buttonText}</LabelLarge>
      </Button>
      {!hasEFTransactionCode && (
        <EnrollmentExtensionDialog
          open={openExtensionDialog}
          onClose={() => setOpenExtensionDialog(false)}
          sisEnrollmentId={enrollment?.sisEnrollmentId ?? 0}
        />
      )}
      <IneligibleToPurchaseExtensionDialog
        open={openInelgibleForExtensionDialog}
        onClose={() => setIneligibleExtensionOpenExtensionDialog(false)}
        expirationDate={enrollment?.expirationDate}
      />
    </>
  );
}
