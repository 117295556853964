import { StatusCodes } from 'http-status-codes';
import React, { useContext, useEffect, useState } from 'react';
import { MyProgramCard, MyTasksCard } from 'src/components/cards';
import EngenCourseCard from 'src/components/cards/engen-course-card';
import EnrollNextSemesterCard from 'src/components/cards/enroll-next-semester-card';
import PurchaseExtensionCard from 'src/components/cards/purchase-extension-card';
import MyPaymentsCard from 'src/components/cards/my-payments-card';
import { LoadingStateFailedModal, GenericErrorModal } from 'src/components/error-modals';
import AppLayout from 'src/components/layout';
import DegreeAuditContext from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { useFetchNextPayment } from 'src/hooks/fetch-payments';
import { useFetchScheduledPayments } from 'src/hooks/fetch-payments';
import { NextPaymentData } from 'src/types/payments';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { IconButton } from '@pennfoster/pfc-design-system';
import { LEGACY_ELECTIVE_PLACEHOLDER } from 'src/constants/cardStatus';
import CurrentCourseCard from '../../components/cards/current-course-card';
import UpcomingCoursesCard from '../../components/cards/upcoming-courses-card';
import { useFetchCourses } from '../../hooks/fetch-courses';
import { Course, CourseLocation } from '../../types/course';
import DashboardLoadingScreen from './loading-screen';
import { getProgramName } from 'src/helpers/get-program-name';
import { useDegreeAuditQuery } from 'src/hooks/degree-audit';
import LoadingComponent from 'src/components/loading-component';
import { getAcademicUXStatus } from 'src/helpers/program-helper';
import { ProgramStatus } from 'src/types/program-status';


export default function Dashboard() {
  const { status: degreeAuditQueryStatus } = useDegreeAuditQuery();
  const { currentTerm } = useContext(DegreeAuditContext);
  const {
    enrollment,
    userEnrollment,
    queryStatus: enrollmentQueryStatus,
    queryError: enrollmentQueryError,
  } = useContext(EnrollmentContext);
  const {
    user,
    queryStatus: userQueryStatus,
    queryError: userQueryError,
    changeSelectedEnrollment,
  } = useContext(UserContext);

  const [nextPaymentData, setNextPaymentData] = useState<NextPaymentData>();
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null);

  const [isLoadingUrlCourses, setIsLoadingUrlCourses] = useState<boolean>(false);
  const [isLoadingEligibleToReEnroll, setIsEligibleToReEnroll] = useState<boolean>(false);

  const coursesQuery = useFetchCourses(enrollment?.enrollmentId);
  const nextPaymentQuery = useFetchNextPayment();
  const { data: scheduledPayments = [], isLoading, error } = useFetchScheduledPayments();
  const showLSAPlaceHolders = currentTerm?.cardStatus.includes(LEGACY_ELECTIVE_PLACEHOLDER) ?? false;
  const academicStatus = enrollment?.academicStatus && getAcademicUXStatus(enrollment?.academicStatus);

  useEffect(() => {
    if (nextPaymentQuery.data) {
      const data = nextPaymentQuery.data?.data;
      setNextPaymentData(data);
    }
  }, [nextPaymentQuery, scheduledPayments]);

  const queriesStatus = [
    nextPaymentQuery.status,
    enrollmentQueryStatus,
    userQueryStatus,
    degreeAuditQueryStatus,
    coursesQuery.status,
  ];

  if (queriesStatus.includes('loading')) {
    return <DashboardLoadingScreen />;
  }

  if (queriesStatus.includes('error')) {
    const userDoesNotExist = userQueryError?.response?.status === StatusCodes.NOT_FOUND;
    const enrollmentDoesNotExist = enrollmentQueryError?.response?.status === StatusCodes.NOT_FOUND;

    if (userDoesNotExist || enrollmentDoesNotExist) {
      return <GenericErrorModal />;
    }
    return <LoadingStateFailedModal />;
  }

  if (!user || !enrollment || !currentTerm) {
    return <LoadingComponent label={'Loading'} />;
  }

  if (isLoadingUrlCourses) {
    return <LoadingComponent label="Entering Classroom" />;
  }

  if (isLoadingEligibleToReEnroll) {
    return <LoadingComponent label="Loading..." />;
  }

  let firstCourse: Course = {} as Course;
  let upcomingCourses: Course[] = [];

  if (coursesQuery.data) {
    firstCourse = coursesQuery.data.data[0];
    if (coursesQuery.data.data.length === 1 || coursesQuery.data.data.length === 2) {
      upcomingCourses = coursesQuery.data.data;
    } else {
      upcomingCourses = coursesQuery.data.data.slice(0, 3);
    }
  }

  const courseCardContent = () => {
    if (firstCourse && firstCourse.courseLocation === CourseLocation.Engen) {
      return <EngenCourseCard course={firstCourse} setIsLoadingUrlCourses={setIsLoadingUrlCourses} />;
    }
    if (enrollment?.eligibleToReEnroll && enrollment?.currentTermCoursesCompleted) {
      return <EnrollNextSemesterCard setIsEligibleToReEnroll={setIsEligibleToReEnroll} />;
    }
    if (enrollment?.enrollmentExtension.eligibleForExtension && academicStatus === ProgramStatus.Expired) {
      return <PurchaseExtensionCard scheduledPayments={scheduledPayments} enrollment={enrollment}/>;
    }
    return (
      <CurrentCourseCard
        course={firstCourse}
        enrollment={enrollment!}
        setIsLoadingUrlCourses={setIsLoadingUrlCourses}
      />
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonElement(event.currentTarget);
  };

  const handleClickMenuItem = (selectedEnrollmentId: string) => {
    setButtonElement(null);
    changeSelectedEnrollment(selectedEnrollmentId);
  };

  return (
    <AppLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography variant="headingH6" component="h1">
                {getProgramName(enrollment)}
              </Typography>
              {userEnrollment && userEnrollment?.enrollments.length > 1 && (
                <IconButton aria-label="Select Program" icon={<KeyboardArrowDown />} onClick={handleClick}></IconButton>
              )}
              <Menu
                id="dropdown-menu"
                anchorEl={buttonElement}
                open={Boolean(buttonElement)}
                onClose={handleClickMenuItem}
              >
                {userEnrollment &&
                  userEnrollment?.enrollments.map((enrollment, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClickMenuItem(enrollment.enrollmentId);
                      }}
                    >
                      {getProgramName(enrollment)}
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </Grid>
          <Grid container item xs={12} md={7} spacing={3}>
            <Grid item xs={12}>
              {/* Make this to show in order by where true is first and only show one at a time. */}
              {courseCardContent()}
              <Box mt={3}>
                <UpcomingCoursesCard
                  courses={upcomingCourses}
                  enrollment={enrollment}
                  setIsLoadingUrlCourses={setIsLoadingUrlCourses}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={5} spacing={3} sx={{ alignContent: 'flex-start' }}>
            <Grid item xs={12}>
              <MyProgramCard />
            </Grid>
            {enrollment.partnerAccountCode == null && (
              <Grid item xs={12}>
                <MyPaymentsCard nextPaymentData={nextPaymentData!} />
              </Grid>
            )}
            <Grid item xs={12}>
              <MyTasksCard
                enrollment={enrollment}
                selectedPathway={enrollment.selectedPathway}
                pathwayStatus={currentTerm.pathwayStatus}
                setIsEligibleToReEnroll={setIsEligibleToReEnroll}
                showLSAPlaceHolders={showLSAPlaceHolders}
                scheduledPayments={scheduledPayments}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
}
