export enum ProgramStatus {
  Active = 'active',
  RequirementsNotMet = 'requirementsNotMet',
  Graduated = 'graduated',
  Inactive = 'inactive',
  Enrolled = 'enrolled',
  Expired = 'expired'
}

export type ProgramStatusLabel =
  | 'Active'
  | 'Requirements Not Met'
  | 'Graduated'
  | 'Inactive'
  | 'Enrolled'
  | 'Expired';

export const ProgramStatusLabels: Record<ProgramStatus, ProgramStatusLabel> = {
  [ProgramStatus.Active]: 'Active',
  [ProgramStatus.RequirementsNotMet]: 'Requirements Not Met',
  [ProgramStatus.Graduated]: 'Graduated',
  [ProgramStatus.Inactive]: 'Inactive',
  [ProgramStatus.Enrolled]: 'Enrolled',
  [ProgramStatus.Expired]: 'Inactive',
};
