import { Box, Grid } from '@mui/material';
import { Alert, Button } from '@pennfoster/pfc-design-system';
import { useContext } from 'react';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import AddCardFormDialog from 'src/components/add-card-form-dialog';
import UserContext from 'src/context/user';
import FeEvents from 'src/events/fe-events';
import { currencyFormatWithDecimals, dateFormatMMddyyyy } from 'src/helpers/format';
import { Body1, Subtitle1 } from 'src/styles';
import { CalculateSalesTaxRequest, PaymentMethod } from 'src/types/payments';
import { IFormInput } from '../make-a-payment-form';
import ChoosePaymentMethod from '../make-a-payment-form/choose-payment-method';
import ConfirmationDialog from '../make-a-payment-form/confirmation-dialog';
import { FormState } from '../make-a-payment-form/state';
import TotalPaymentAmount from '../make-a-payment-form/total-payment-amount';
import ConfirmationPaymentCard from 'src/components/cards/confirmation-payment-card';
import NotificationPaymentErrorCard from 'src/components/cards/notification-payment-error-card';
import ChooseProgramSkeleton from '../make-a-payment-form/skeleton/choose-program-skeleton';
import TotalPaymentSkeleton from '../make-a-payment-form/skeleton/total-payment-skeleton';

interface PurchaseAnExtensionProps {
  programDisplayName: string,
  amountDue: number | null,
  dueDate: Date | null,
  state: FormState,

  handleClickChange: () => void;
  onClickPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setAddPaymentMethodOpen: (isOpen: boolean) => void;
  handleCancelClick: () => void;
  buildSalesTaxRequestObject: () => CalculateSalesTaxRequest;
  setDialogOpen: (isOpen: boolean) => void;
  formData: IFormInput;
  handleSubmit: UseFormHandleSubmit<IFormInput, undefined>;
  onSubmit: SubmitHandler<IFormInput>;
  setNewCard: (newCard: PaymentMethod) => void;
  handleClose: () => void;
}
export default function PurchaseAnExtensionForm({
  programDisplayName,
  amountDue,
  dueDate,
  state,
  handleClickChange,
  onClickPaymentMethod,
  setAddPaymentMethodOpen,
  handleCancelClick,
  buildSalesTaxRequestObject,
  setDialogOpen,
  formData,
  handleSubmit,
  onSubmit,
  setNewCard,
  handleClose,
}: PurchaseAnExtensionProps) {
  const { user } = useContext(UserContext);
  const {
    dialogOpen,
    addPaymentMethodOpen,
    isChanged,
    defaultCard,
    paymentMethods,
    paymentTaxesResponse,
    isLoadingTaxes,
    isErrorOnTaxes,
    isLoading,
    notificationProps,
  } = state;

  const amount = amountDue ?? 0;
  const paymentText = `${currencyFormatWithDecimals(amount)} - ${programDisplayName ?? ''} - Program Extension - ${dateFormatMMddyyyy(dueDate?.toString() ?? '') ?? ''}`;

  return (
    <>
      {notificationProps === null ? <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 3,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Subtitle1 variant="h2">Program</Subtitle1>
              <Box sx={{ pt: 2 }}>
                {programDisplayName ? <Body1>{programDisplayName}</Body1> : <ChooseProgramSkeleton />}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Subtitle1 variant="h2">Payment</Subtitle1>
              <Box sx={{ pt: 2 }}>
                {amountDue ? <Body1>{paymentText}</Body1> : <ChooseProgramSkeleton />}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {amountDue ? <TotalPaymentAmount amount={amount} isLoading={false} /> : <TotalPaymentSkeleton/>}
            </Grid>
            <Grid item xs={12}>
              <ChoosePaymentMethod
                isChanged={isChanged}
                handleClickChange={handleClickChange}
                paymentMethods={paymentMethods}
                onClickPaymentMethod={onClickPaymentMethod}
                defaultCard={defaultCard}
                setAddPaymentMethodOpen={setAddPaymentMethodOpen}
                user={user}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%', pb: 2 }}>
              <Alert pfVariant="standard" severity="success" sx={{ width: '100%' }}>
                You have succesfully requested an extension.
              </Alert>
            </Box>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                alignContent: 'flex-end',
                justifyContent: 'flex-end',
                padding: 0,
                right: 0,
              }}
            >
              <Button label="Cancel" pfVariant="text" size="large" onClick={handleCancelClick} />
              <Button
                label="Continue"
                disabled={paymentMethods.length == 0 || defaultCard == null}
                pfVariant="filled"
                color="primary"
                size="large"
                onClick={() => {
                  buildSalesTaxRequestObject();
                  setDialogOpen(true);
                  FeEvents.trackNamedEvent({
                    eventName: 'ConfirmClickEvent',
                  });
                }}
              />
            </Box>
          </Box>
          <ConfirmationDialog
            dialogOpen={dialogOpen}
            amount={amount}
            taxAmount={paymentTaxesResponse?.totalAmount ?? 0}
            planName={programDisplayName}
            paymentMethod={defaultCard}
            setDialogOpen={setDialogOpen}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isLoadingTaxes={isLoadingTaxes}
            isErrorOnTaxes={isErrorOnTaxes}
          />
          <AddCardFormDialog handleClose={handleClose} open={addPaymentMethodOpen} emitNewCard={setNewCard} />
        </form>
      </Box> : (
        <Grid item xs={12}>
          {notificationProps.errorTitle !== 'Success' ? (
            <NotificationPaymentErrorCard
              errorTitle={notificationProps.errorTitle}
              errorMessage={notificationProps.errorMessage}
              makePayment={false}
              extensionPayment={true}
              setNotificationProps={notificationProps.setNotificationProps}
            />
          ) : (
            <ConfirmationPaymentCard confirmationMessage="" />
          )}
        </Grid>
      )
      }
    </>
  );
}
