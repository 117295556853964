import { getCourses } from './courses';
import { getDegreeAudit } from './degree-audit';
import {
  getElectivePools,
  getEnrollment,
  getEnrollments,
  postElectives,
  switchEnrollment,
  getEolUrl,
  getLegacyPortalUrl,
  getUserEnrollmentDocuments,
} from './enrollments';
import { getTransactionHistory } from './ledger';
import { getStates } from './location';
import {
  deleteMessage,
  getMessage,
  getMessages,
  getMessagesByFilter,
  updateMessageAsRead,
  updateMessageAsUnread,
} from './messages';
import {
  getPaymentPlans,
  getScheduledPayments,
  getEnrollmentScheduledPayments,
  getNextPaymentData,
} from './payment';
import {
  addPaymentMethod,
  getPaymentMethods,
  makeDefault,
  makeBackup,
  updatePaymentMethod,
  getPaymentMethodsDefaultBackup,
} from './payment-methods';
import { getPathways, postPathway } from './pathway';
import { getLogo, getTheme } from './themes';
import { getUser, getUserProfile, updateUserProfile } from './user';
import instance from './apiConfig';
import { getSasToken, getHtmlBlob } from './sastoken';
import { createRoom } from './cobrowse';

interface LoginUser {
  username: string;
  password: string;
}
async function login({ username, password }: LoginUser) {
  return await instance({
    method: 'GET',
    url: `/authentication?username=${username}&password=${password}`,
  });
}

export interface IPageReq {
  currentPage?: number;
  perPage?: number;
}
export interface IPage<T> {
  meta: IPageMeta;
  data: T[];
}
export interface IPageMeta {
  currentPage: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
}
export interface IQuery<T = any> {
  pageReq?: IPageReq;
  filters?: IFilter[];
  sorts?: ISort[];
}
export interface IFilter {
  column: string;
  operator: 'eq' | 'ne' | 'gt' | 'lt' | 'gte' | 'lte' | 'like' | 'in';
  value: string | number | boolean | Date | Array<string | number | Date>;
}
export interface ISort {
  column: string;
  direction: 'asc' | 'desc';
}

export class Query<T = any> implements IQuery<T> {
  public pageReq: IPageReq = new PageReq();
  public filters: IFilter[] = [];
  public sorts: ISort[] = [];

  public constructor(query?: IQuery) {
    if (query) {
      if (query.pageReq && query.pageReq instanceof PageReq)
        this.pageReq = query.pageReq;
      else this.pageReq = new PageReq();
      if (query.pageReq) {
        if (query.pageReq.currentPage)
          this.pageReq.currentPage = query.pageReq.currentPage;
        if (query.pageReq.perPage) this.pageReq.perPage = query.pageReq.perPage;
      }
      if (Array.isArray(query.filters)) this.filters = query.filters;
      if (Array.isArray(query.sorts)) this.sorts = query.sorts;
    }
  }

  private getAsQuery(): any {
    let query: { [key: string]: any } = {
      currentPage: this.pageReq.currentPage,
      perPage: this.pageReq.perPage,
    };
    query = this.filters.reduce((query, filter) => {
      if (!query[filter.column]) {
        query[filter.column] = {};
      }
      query[filter.column] = Object.assign(
        {},
        query[filter.column],
        (() => {
          const obj: { [key: string]: string | number | boolean | Date } = {};
          obj[filter.operator] = (() => {
            switch (filter.operator) {
              case 'eq':
              case 'ne':
                if (!Array.isArray(filter.value)) {
                  if (filter.value instanceof Date)
                    return filter.value.toISOString();
                  else return filter.value;
                }
                throw new Error('Invalid filter');
              case 'gt':
              case 'lt':
              case 'gte':
              case 'lte':
                if (
                  !Array.isArray(filter.value) &&
                  !(typeof filter.value == 'boolean')
                ) {
                  if (filter.value instanceof Date)
                    return filter.value.toISOString();
                  else return filter.value;
                }
                throw new Error('Invalid filter');
              case 'like':
                if (
                  !Array.isArray(filter.value) &&
                  !(filter.value instanceof Date) &&
                  !(typeof filter.value == 'boolean')
                )
                  return filter.value;
                throw new Error('Invalid filter');
              case 'in':
                if (Array.isArray(filter.value)) {
                  return filter.value
                    .map((value) => {
                      if (value instanceof Date) {
                        return value.toISOString();
                      } else {
                        return value;
                      }
                    })
                    .join(',');
                }
                throw new Error('Invalid filter');
              default:
                throw new Error('Invalid filter');
            }
          })();
          return obj;
        })()
      );
      return query;
    }, query);
    if (this.sorts.length > 0) {
      query = Object.assign({}, query, {
        sortBy: this.sorts
          .map((sort) => {
            return `${sort.column}:${sort.direction}`;
          })
          .join(','),
      });
    }
    return query;
  }

  public static serializeQuery(
    params: any,
    prefix: string | number = ''
  ): string {
    const query: string[] = Object.keys(params).map((key: string | number) => {
      const value: any = params[key];
      if (params.constructor === Array) key = `${prefix}[]`;
      else if (params.constructor === Object)
        key = prefix ? `${prefix}[${key}]` : key;
      if (typeof value === 'object') return Query.serializeQuery(value, key);
      else return `${key}=${/*encodeURIComponent(*/ value /*)*/}`;
    });
    return ([] as Array<string>).concat.apply([], query).join('&');
  }

  public toQueryString(): string {
    return Query.serializeQuery(this.getAsQuery());
  }
}

export class PageReq implements IPageReq {
  public currentPage = 1;
  public perPage = 25;
  private minPage = 1;
  private maxPage = -1;

  public constructor(pageReq?: IPageReq) {
    if (pageReq) {
      if (pageReq.currentPage) this.currentPage = pageReq.currentPage;
      if (pageReq.perPage) this.perPage = pageReq.perPage;
    }
  }

  public nextPage(): void {
    if (
      this.maxPage == -1 ||
      (this.maxPage > -1 && this.currentPage < this.maxPage)
    )
      this.currentPage++;
  }

  public prevPage(): void {
    if (this.currentPage > this.minPage) this.currentPage--;
  }

  public gotoPage(pageNumber: number): void {
    if (pageNumber >= this.minPage && pageNumber <= this.maxPage)
      this.currentPage = pageNumber;
  }
}

export {
  login,
  getUser,
  getUserProfile,
  getUserEnrollmentDocuments,
  updateUserProfile,
  getCourses,
  getEnrollment,
  getEnrollments,
  getTransactionHistory,
  postElectives,
  getElectivePools,
  getDegreeAudit,
  getPaymentMethods,
  getStates,
  getTheme,
  getLogo,
  addPaymentMethod,
  updatePaymentMethod,
  makeDefault,
  makeBackup,
  getMessages,
  getMessagesByFilter,
  getMessage,
  getPaymentPlans,
  getSasToken,
  getHtmlBlob,
  getScheduledPayments,
  getEnrollmentScheduledPayments,
  getNextPaymentData,
  updateMessageAsRead,
  updateMessageAsUnread,
  deleteMessage,
  getPathways,
  postPathway,
  getPaymentMethodsDefaultBackup,
  switchEnrollment,
  getEolUrl,
  getLegacyPortalUrl,
  createRoom,
};
