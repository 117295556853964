import { useContext, useEffect, useReducer, useState } from "react";
import {
    CalculateSalesTaxRequest, ChargeStudent,
    ChargeStudentItem,
    NotificationPaymentErrorCardProps, PaymentMethod,
    PaymentResult, PaymentTaxesResponse, SalesTaxItem
} from "src/types/payments";

import { AxiosError, AxiosResponse } from "axios";
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getPaymentMethods } from "src/api";
import { getEnrollmentScheduledPayments, getPaymentTaxes, makePayment } from "src/api/payment";
import EnrollmentContext from "src/context/enrollment";
import UserContext from "src/context/user";
import { PAYMENTS_OVERVIEW } from "src/routes/routemap";
import QueryKeys from "src/types/query-keys";
import { IFormInput } from "../make-a-payment-form";
import { initialState, reducer } from "../make-a-payment-form/state";
import PurchaseAnExtensionForm from "./purchse-an-extension-form";
import { ExtensionProps } from "src/pages/purchase-an-extension";

export default function PurchaseExtension(enrollmentExtension?: ExtensionProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { enrollment } = useContext(EnrollmentContext);
    const { user } = useContext(UserContext);
    const [extensionScheduledPayment, setExtensionScheduledPayment] = useState<ExtensionProps | undefined>(enrollmentExtension);
    const {
        addPaymentMethodOpen,
        defaultCard,
        newCard,
        paymentMethods,
        paymentTaxesResponse,
        salesTaxRequest,
    } = state;


    useEffect(() => {
        if (paymentMethodsQuery.data?.data) {
            dispatch({
                type: 'SET_PAYMENT_METHODS',
                payload: paymentMethodsQuery.data?.data,
            });
            const defaultCard = paymentMethodsQuery.data?.data.find((card) => card.isDefault);
            setDefaultPaymentMethod(defaultCard, paymentMethodsQuery.data?.data);
        }

        if (enrollmentExtension && Object.keys(enrollmentExtension).length === 0) {
            (async () => {
                if (enrollment?.enrollmentId) {
                    const resp = await getEnrollmentScheduledPayments(enrollment?.enrollmentId);
                    if (resp.data && Array.isArray(resp.data?.paymentsScheduled)) {
                        const efScheduledPayment = resp.data.paymentsScheduled.find(payment => payment.billingTransactionCode === 'EF');
                        setExtensionScheduledPayment(efScheduledPayment || enrollmentExtension);
                    }
                }
            })();
        }

    }, []);
    useEffect(() => {
        if (newCard) {
            dispatch({
                type: 'SET_PAYMENT_METHODS',
                payload: [newCard, ...paymentMethods],
            });
            dispatch({ type: 'SET_DEFAULT_CARD', payload: newCard });
            dispatch({ type: 'SET_IS_CHANGED', payload: false });
        }
    }, [newCard]);
    const handleClickChange = async () => {
        dispatch({ type: 'SET_IS_CHANGED', payload: !state.isChanged });
    };
    const onClickPaymentMethod = (paymentMethod: PaymentMethod) => {
        dispatch({ type: 'SET_DEFAULT_CARD', payload: paymentMethod });
    };
    const setAddPaymentMethodOpen = (isOpen: boolean) =>
        dispatch({ type: 'SET_ADD_PAYMENT_METHOD_OPEN', payload: isOpen });
    const navigate = useNavigate();
    function handleCancelClick() {
        navigate(PAYMENTS_OVERVIEW);
    }
    const setNotificationProps = (notificationProps: NotificationPaymentErrorCardProps | null) =>
        dispatch({ type: 'SET_NOTIFICATION_PROPS', payload: notificationProps });
    const paymentMethodsQuery = useQuery([QueryKeys.GET_PAYMENT_METHOD_LIST], async () => await getPaymentMethods(), {
        onSuccess: (resp: AxiosResponse<PaymentMethod[]>) => {
            dispatch({ type: 'SET_PAYMENT_METHODS', payload: resp.data });
            const defaultCard = resp.data.find((card) => card.isDefault && card.type === 'ach') ?? resp.data.find((card) => card.isDefault);
            setDefaultPaymentMethod(defaultCard, resp.data);
        },
        onError: (error) => console.error(error),
    });
    function setDefaultPaymentMethod(defaultCard: PaymentMethod | undefined, paymentMethodsProp: PaymentMethod[]) {
        if (defaultCard) {
            dispatch({ type: 'SET_DEFAULT_CARD', payload: defaultCard });
        } else if (paymentMethodsProp.filter(p => p.nameOnCard !== null).length >= 1) {
            dispatch({ type: 'SET_DEFAULT_CARD', payload: paymentMethodsProp[0] });
        }
    }
    const cardDefaultError: NotificationPaymentErrorCardProps = {
        errorTitle: 'There was an error processing your card',
        errorMessage: 'There was an error making this transaction. No charges were made to your account. Please try again.',
        makePayment: true,
        setNotificationProps,
    };

    const buildSalesTaxRequestObject = (): CalculateSalesTaxRequest => {
        const salesTaxItems: SalesTaxItem[] = [];

        salesTaxItems.push({
            amount: extensionScheduledPayment?.amountDue ?? 0,
            type: 'EXTENSION_FEE',
            termId: extensionScheduledPayment?.sisTermId ?? 0,
        });

        const salesTax: CalculateSalesTaxRequest = {
            payInFull: false,
            chargedAmount: salesTaxItems[0].amount,
            salesTaxItems: salesTaxItems.length > 0 ? salesTaxItems : [],
            sisPaymentMethodId: defaultCard!.id!,
            sisPaymentPlanId: extensionScheduledPayment?.sisPaymentPlanId ?? 0,
        };
        dispatch({ type: 'SET_SALES_TAX_REQUEST', payload: salesTax });

        return salesTax;
    };

    const { refetch } = useQuery(
        [QueryKeys.GET_PAYMENT_TAXES, salesTaxRequest],
        async () => {
            dispatch({ type: 'SET_IS_LOADING_TAXES', payload: true });
            if (salesTaxRequest && enrollment?.enrollmentId) {
                return await getPaymentTaxes(salesTaxRequest, enrollment?.enrollmentId);
            }
            return null;
        },
        {
            onSuccess: (resp: AxiosResponse<PaymentTaxesResponse>) => {
                dispatch({ type: 'SET_IS_ERROR_ON_TAXES', payload: false });
                if (resp && resp.data) {
                    dispatch({ type: 'SET_PAYMENT_TAXES_RESPONSE', payload: resp.data });
                }
                dispatch({ type: 'SET_IS_LOADING_TAXES', payload: false });
            },
            onError: (error) => {
                console.error('Error: ', error);
                dispatch({ type: 'SET_IS_LOADING_TAXES', payload: false });
                dispatch({ type: 'SET_IS_ERROR_ON_TAXES', payload: true });
            },
            enabled: !!salesTaxRequest,
        }
    );

    useEffect(() => {
        if (salesTaxRequest) {
            refetch()
        }
    }, [salesTaxRequest, refetch]);
    const buildPayExtensionObject = (): ChargeStudent => {
        const chargeItem = {
            type: 'EXTENSION_FEE',
            amount: extensionScheduledPayment?.amountDue ?? 0,
            termId: extensionScheduledPayment?.sisTermId ?? 1,
            taxAmount:
                paymentTaxesResponse?.salesTaxTransactions?.find((x) => x.termId === extensionScheduledPayment?.sisTermId)?.taxAmount ?? 0,
            sisPaymentScheduledId: undefined,
        } as ChargeStudentItem;
        return {
            payInFull: false,
            baseAmount: extensionScheduledPayment?.amountDue ?? 0,
            paymentType: defaultCard?.type ?? 'unknown',
            totalTaxAmount: paymentTaxesResponse?.totalAmount ?? 0,
            chargeStudentItems: [chargeItem],
            sisPaymentMethodId: defaultCard!.id!,
            sisPaymentPlanId: 0,
        };
    };

    const onSubmit: SubmitHandler<IFormInput> = () => {
        setIsLoading(true);
        if (!addPaymentMethodOpen) {
            const extensionPayment = buildPayExtensionObject();

            return new Promise((resolve, reject) => {
                makePayment(enrollment?.enrollmentId.toString() ?? '', extensionPayment)
                    .then((result: PaymentResult) => {
                        resolve(result);
                        if (!result.data.success) {
                            setNotificationProps(
                                {
                                    errorTitle: 'Payment Failed',
                                    errorMessage: result.data?.messages[0],
                                    makePayment: false,
                                    setNotificationProps
                                }
                            );
                        }
                        else {
                            setNotificationProps({
                                errorTitle: 'Success',
                                errorMessage: 'Success',
                                makePayment: true,
                                setNotificationProps,
                            });
                        }
                        setDialogOpen(false);
                        setIsLoading(false);
                        dispatch({
                            type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
                            payload: [],
                        });
                    })
                    .catch((error: AxiosError) => {
                        setNotificationProps(cardDefaultError);
                        setDialogOpen(false);
                        setIsLoading(false);
                        reject(error);
                    });
            });
        }
    };
    const {
        handleSubmit: handleSubmit,
        watch,
    } = useForm<IFormInput>({
        defaultValues: {
            enrollmentId: enrollment?.enrollmentId,
            studentId: user?.sisId,
            sisPaymentPlanId: undefined,
        },
        mode: 'onSubmit',
    });
    const formData = watch();
    const setDialogOpen = (isOpen: boolean) => dispatch({ type: 'SET_DIALOG_OPEN', payload: isOpen });
    const setNewCard = (newCard: PaymentMethod) => dispatch({ type: 'SET_NEW_CARD', payload: newCard });
    const setIsLoading = (isLoading: boolean) => dispatch({ type: 'SET_IS_LOADING', payload: isLoading });
    const handleClose = () => {
        setAddPaymentMethodOpen(false);
        setIsLoading(false);
    };
    const puchaseProps = {
        programDisplayName: extensionScheduledPayment!.programName ?? extensionScheduledPayment!.programDisplayName,
        amountDue: extensionScheduledPayment!.amountDue,
        dueDate: extensionScheduledPayment!.dueDate,
        state: state,
        handleClickChange,
        onClickPaymentMethod,
        setAddPaymentMethodOpen,
        handleCancelClick,
        buildSalesTaxRequestObject,
        setDialogOpen,
        formData,
        handleSubmit,
        onSubmit,
        setNewCard,
        handleClose
    }
    return (<PurchaseAnExtensionForm {...puchaseProps} />)
}
