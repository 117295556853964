import React, { useContext, useState } from 'react';
import { ReactComponent as NoMessagesIcon } from 'src/assets/svg/no-messages.svg';
import { Body1, HeadingH6, Subtitle1, MessageCaption } from 'src/styles';
import { InboxMessage } from 'src/types/message';

import { ArrowBack } from '@mui/icons-material';
import { alpha, Box, MenuItem, useTheme } from '@mui/material';
import { Button, Drawer } from '@pennfoster/pfc-design-system';
import InboxSkeleton from './inbox-skeleton';
import MessageItem from './message-item';
import { dateFormat } from 'src/helpers/format';
import NotificationContext from 'src/context/notifications';
import { ListItemSkeleton } from '../skeleton-components';
import NotificationIcon from './notification-icon';

interface Props {
  mobile?: boolean;
}

export default function NotificationsDrawer({ mobile }: Props) {
  const theme = useTheme();
  const btnOutlineColor = alpha(theme.palette.primary.main, 0.1);
  const [
    {
      queryStatus,
      notificationCount,
      inboxMessages,
      selectedMessage,
      unreadOnly,
      updateMessageToRead,
      updateMessageToUnread,
      setSelectedMessage,
      setUnreadOnly,
    },
    dispatch,
  ] = useContext(NotificationContext);
  const [open, setOpen] = useState(false);
  const [activeMessageOpen, setActiveMessageOpen] = useState(false);

  function handleMessageClick(message: InboxMessage) {
    setActiveMessageOpen(true);
    setSelectedMessage(message, dispatch);
    updateMessageToRead(message);
  }

  function toggleActiveMessageDrawer() {
    setSelectedMessage(undefined, dispatch);
    setActiveMessageOpen(false);
  }

  function handleMarkAsUnread(message: InboxMessage | undefined) {
    if (message) {
      updateMessageToUnread(message);
      toggleActiveMessageDrawer();
    }
  }

  function closeBoth() {
    setSelectedMessage(undefined, dispatch);
    setActiveMessageOpen(false);
    toggleDrawer();
  }

  function filterInbox(filtered: boolean) {
    setUnreadOnly(filtered, dispatch);
  }

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <NotificationIcon mobile={mobile} notificationCount={notificationCount} toggleDrawer={toggleDrawer} />

      <Drawer
        slotProps={{ backdrop: { invisible: true } }}
        anchor="right"
        toggleDrawer={toggleDrawer}
        open={open}
        Header={
          <>
            <HeadingH6 variant="h2">Message Center</HeadingH6>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: 1,
              }}
            >
              <Button
                sx={{
                  padding: `${theme.spacing(1.25)} ${theme.spacing(0.75)}`,
                  '&:not(:disabled)': {
                    backgroundColor: !unreadOnly ? btnOutlineColor : 'transparent',
                  },
                  '&:hover': {
                    backgroundColor: btnOutlineColor,
                  },
                }}
                onClick={() => filterInbox(false)}
                label="All"
                pfVariant="text"
              />
              <Button
                sx={{
                  left: theme.spacing(2),
                  '&:not(:disabled)': {
                    backgroundColor: unreadOnly ? btnOutlineColor : 'transparent',
                  },
                  '&:hover': {
                    backgroundColor: btnOutlineColor,
                  },
                }}
                onClick={() => filterInbox(true)}
                label={notificationCount > 0 ? `Unread (${notificationCount})` : 'Unread'}
                pfVariant="text"
              />
            </Box>
            <hr
              style={{
                color: 'rgba(32, 40, 52, 0.15)',
                height: '1px',
                left: 0,
                margin: 0,
                position: 'absolute',
                right: 0,
                top: theme.spacing(14),
                width: '100%',
              }}
            />
          </>
        }
      >
        {!inboxMessages || queryStatus === 'loading' ? (
          <InboxSkeleton />
        ) : (
          <>
            {inboxMessages.length > 0 ? (
              inboxMessages.map((message) => (
                <MessageItem
                  key={`${message.id}-${message.createdDate}`}
                  message={message}
                  handleMessageClick={handleMessageClick}
                />
              ))
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '35%',
                  transform: 'translateY(-50%)',
                  left: 0,
                  right: 0,
                  maxWidth: '245px',
                  margin: '0 auto',
                  textAlign: 'center',
                }}
              >
                <NoMessagesIcon />
                <Subtitle1
                  sx={{
                    paddingTop: theme.spacing(4),
                    paddingBottom: theme.spacing(1),
                  }}
                >
                  There are no messages yet
                </Subtitle1>
                <Body1>Incoming messages will be listed in this folder</Body1>
              </Box>
            )}
          </>
        )}
      </Drawer>
      <Drawer
        slotProps={{ backdrop: { invisible: true } }}
        anchor="right"
        open={activeMessageOpen}
        toggleDrawer={closeBoth}
        Header={
          <Box>
            <Button
              sx={{
                color: theme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: btnOutlineColor,
                },
              }}
              onClick={toggleActiveMessageDrawer}
              label="Back"
              pfVariant="text"
              icon={<ArrowBack sx={{ marginLeft: theme.spacing(-1.25) }} />}
            />
            <Subtitle1
              sx={{
                color: theme.palette.neutral[20],
                fontWeight: 600,
                mt: theme.spacing(2),
              }}
            >
              {selectedMessage?.subject}
            </Subtitle1>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontSize: '12px !important',
                justifyContent: 'space-between',
              }}
            >
              {selectedMessage && selectedMessage.createdDate ? (
                <MessageCaption
                  sx={{
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.004em',
                    mt: theme.spacing(1),
                  }}
                >
                  {dateFormat(selectedMessage.createdDate)}
                </MessageCaption>
              ) : (
                <ListItemSkeleton />
              )}
              <Button
                sx={{
                  mt: theme.spacing(1),
                  mr: theme.spacing(-3.5),
                }}
                onClick={() => handleMarkAsUnread(selectedMessage)}
                label="Mark as unread"
                pfVariant="text"
              />
            </Box>
          </Box>
        }
      >
        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
          {selectedMessage?.body ? selectedMessage?.body : <InboxSkeleton />}
        </Box>
      </Drawer>
    </>
  );
}
