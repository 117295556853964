import { Box, Grid } from '@mui/material';
import AppLayout from '../../components/layout';
import { HeadingH6 } from 'src/styles';
import PurchaseExtension from 'src/components/forms/purchase-an-extension-form';
import { useLocation } from 'react-router-dom';

export interface ExtensionProps {
  sisPaymentScheduledId: number | null,
  billingTransactionCode: string | null,
  sisTermId: number | null,
  programName: string | null,
  programDisplayName: string,
  status: string | null,
  type: string | null,
  amountDue: number | null,
  dueDate: Date | null,
  sisPaymentPlanId: number | null,
  chargeId: number | null
};

export default function PurchaseAnExtension() {
  const location = useLocation();
  const enrollExtension = location.state as ExtensionProps;

  return (
    <AppLayout>
      <Box
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'center',
          margin: '0 auto',
          px: '24px',
          maxWidth: '580px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeadingH6 variant="h1">Purchase an Extension</HeadingH6>
          </Grid>
          <Grid item xs={12}>
            <PurchaseExtension
              {...enrollExtension}
            />
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
}
