import { getDefaultStore } from 'jotai';
import { BaseSignalRConnection } from './base-signalr-connection';
import { createClickStreamData } from './click-stream-data-factory';
import { currentEnrollmentAtom, userAtom } from 'src/state';
import { ClickStreamEventData, StreamEvent, trackedEvent } from 'src/types/streamed-events';
import { SIGNALR_FE_EVENTS } from 'src/routes/routemap';
import session from 'src/state/session';

const atomStore = getDefaultStore();
class FeEvents extends BaseSignalRConnection {
  MAX_RETRIES = 5;
  retryCount = 0;
  constructor() {
    super(SIGNALR_FE_EVENTS || '');
  }

  // Overriding the startConnection to add custom behavior
  public async startConnection(): Promise<void> {
    await super.startConnection();
  }
  public async init() {
    try {
      await this._connection.start();
      this._isConnected = true;
      this._connection.on('Registered', (message: string) => {
        console.info(message);
      });
    } catch (error) {
      this._isConnected = false;
    }
  }

  private async checkConnectionAndConnect() {
    if (!this.isConnected) {
      await this.init();
    }
  }

  private getUserData() {
    return {
      UserID: atomStore.get(userAtom)?.id.toString(),
      UserAzureIdpId: atomStore.get(userAtom)?.azureIdpId,
      EnrollmentID: atomStore.get(userAtom)?.currentEnrollmentId,
      Session: session.getSession(),
      StudentNumber: atomStore.get(userAtom)?.studentNumber,
      SISStudentId: atomStore.get(userAtom)?.sisId.toString(),
      EnrollmentNumber: atomStore.get(currentEnrollmentAtom)?.enrollmentNumber,
      SISEnrollmentId: atomStore.get(currentEnrollmentAtom)?.sisEnrollmentId,
    };
  }

  // This method is specific to ClickStream and handles click events
  handleClickStreamEvent(e: MouseEvent): void {
    const csEvent = createClickStreamData(e);
    this.trackClickEvent(csEvent);
  }

  trackClickEvent(clickStreamEventData: ClickStreamEventData) {
    const eventObject = [
      {
        EventType: 'Click-Stream',
        User: this.getUserData(),
        EventData: clickStreamEventData,
      },
    ];
    this.trackEvent(eventObject);
  }

  trackNamedEvent(object: trackedEvent) {
    const eventObject = [
      {
        EventType: 'Named-Event',
        User: this.getUserData(),
        EventData: {
          eventName: object.eventName,
          dateTime: new Date(),
          dataSource: object.dataSource,
        },
      },
    ];
    this.trackEvent(eventObject);
  }

  trackPendoEvent(object: trackedEvent) {
    const eventObject = [
      {
        EventType: 'Pendo-Event',
        User: this.getUserData(),
        EventData: {
          eventName: object.eventName,
          dateTime: new Date(),
          dataSource: object.dataSource,
        },
      },
    ];
    this.trackEvent(eventObject);
  }

  trackLoginEvent(object: trackedEvent) {
    const userId = atomStore.get(userAtom)?.id?.toString();
    if (!userId && this.retryCount < this.MAX_RETRIES) {
      this.retryCount++;
      setTimeout(() => {
        this.trackLoginEvent({ eventName: 'Sign-On' });
      }, 5000);
      return;
    } else if (this.retryCount >= this.MAX_RETRIES) {
      console.warn('Max retries reached, failed to get userId');
      return;
    }

    this.retryCount = 0; // Reset retry count once userId is available
    const eventObject = [
      {
        EventType: 'Login-Event',
        User: this.getUserData(),
        EventData: {
          eventName: object.eventName,
          dateTime: new Date(),
          dataSource: object.dataSource,
        },
      },
    ];
    this.trackEvent(eventObject);
  }

  async trackEvent(events: StreamEvent[]) {
    await this.checkConnectionAndConnect();
    if (this.isConnected) {
      this.connection.send('SendJsonMessage', JSON.stringify(events));
    }
  }
}

export default new FeEvents();
