// useFeatureVariable.ts
import { useEffect, useState } from 'react';
import { useVWO } from '../providers/vwo/vwo-provider';

export const useFeatureVariable = (
  featureKey: string,
  variableKey: string,
  userId: string,
  options: Record<string, any> = {}
) => {
  const { vwoInstance } = useVWO();
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (vwoInstance) {
      const variableValue = vwoInstance.getFeatureVariableValue(featureKey, variableKey, userId, options);
      setValue(variableValue);
    }
  }, [vwoInstance, featureKey, variableKey, userId, options]);

  return value;
};