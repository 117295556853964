import { AxiosResponse } from 'axios';
import { instance } from './apiConfig';

interface CoBrowseRoom {
    room: string;
    token: string;
    leadertoken: string;
    privateinvitation: string;
    publicinvitation: string;
    success: boolean
}

export function createRoom(
): Promise<AxiosResponse<CoBrowseRoom>> {
    const startUrl = window.location.href;
    const domain = window.location.host;
    const userId = sessionStorage.getItem('userId');
    const refreshToken = localStorage.getItem('refreshToken') ?? sessionStorage.getItem('refreshToken') ;
    return instance({
        method: 'POST',
        url: `/createroom`,
        data: {
            startUrl,
            session: {
                storage: [
                    {
                        domain,
                        name: 'userId',
                        value: userId,
                    },
                    {
                        domain,
                        name: 'refreshToken',
                        value: refreshToken,
                    }
                ]
            }
        }
    });
}